









































import {Component, Prop, Vue} from 'vue-property-decorator';
import {MachineImage} from "@/models/machineImage";
import ImageRepository from "@/api/ImageRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";

const imageRepository: ImageRepository = RepositoryFactory.get('image');

@Component({})
export default class ImageCardComponent extends Vue {

  @Prop({default: () => []})
  public images!: MachineImage[];

  public showFullscreen = false;
  public selectedImage: MachineImage | null = null;

  public showImageFullscreen(image: MachineImage) {
    this.selectedImage = image;
    this.showFullscreen = true;
  }

  public getImageString(image: MachineImage): string {

    if (image.image.startsWith("data")) {
      return `${image.image}`
    } else return `data:image/png;base64,${image.image}`
  }

  public async deleteImage(image: MachineImage) {
    try {
      const response = await imageRepository.deleteImage(image);
      if (response.data.id) {
        this.$emit('removedImage', image);
      }
    } catch (e) {
      // TODO: Show error
    }
  }

}
